<template>
  <v-container>
    <v-row class="mb-4">
      <v-toolbar elevation="2" class="pa-0" height="48">
        <v-toolbar-title>
          Виробничий календар
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small class="mr-2" @click.stop="deleteDialog">Заповнити</v-btn>
        <v-btn small @click.stop="saveRows">Записати</v-btn>
      </v-toolbar>
    </v-row>
    <v-row align="center" justify="center">
      <div style="flex: 1; display: flex; flex-wrap: wrap; justify-content: flex-start">
        <v-menu
            v-model="showMenu"
            :position-x="x"
            :position-y="y"
            absolute
            offset-y
        >
          <v-list nav dense>
            <v-list-item
                v-for="(item, index) in contextMenuItems"
                :key="index"
                @click.stop="onContextMenuClick(item)"
                dense
            >
              <v-list-item-icon>
                <v-icon :color="item.color">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-responsive width="280" max-width="280"  v-for="(month, idx_month) in items"
                      :key="idx_month">
          <v-card class="mr-4 mb-7" elevation="3" :disabled="is_new">
            <div class="calendar-header">
              <div class="calendar-title">
                {{ month.name }} {{ month.year }} р.
              </div>
              <div class="calendar-row">
                <div class="calendar-col">Пн</div>
                <div class="calendar-col">Вт</div>
                <div class="calendar-col">Ср</div>
                <div class="calendar-col">Чт</div>
                <div class="calendar-col">Пт</div>
                <div class="calendar-col">Сб</div>
                <div class="calendar-col">Нд</div>
              </div>
            </div>
            <div class="calendar-body">
              <div class="calendar-row"
                   v-for="(week, idx_week) in month.weeks"
                   :key="`${idx_month}-${idx_week}`"
              >
                <div class="calendar-col"
                     :class="day.day === undefined ? `disable ${day.class}` : `${day.class}`"
                     v-for="(day, idx_day) in week"
                     :key="`${idx_month}-${idx_week}-${idx_day}`"
                     @contextmenu="clickOnElement($event, day)"
                >
                  {{day.day}}
                </div>
              </div>
            </div>
          </v-card>
        </v-responsive>
      </div>
      <div style="flex: 0 0 300px; align-self: flex-start">
        <v-card class="transparent py-0" tile elevation="0">
          <div style="display: flex; padding-left: 8px; margin-bottom: 12px">
            <v-btn depressed small color="success" height="34"
                   @click.stop="year--"
            >
              <v-icon>mdi-minus</v-icon>
            </v-btn>
            <div
                style="flex: 1; text-align: center;
                 background-color: white; font-weight: 500;
                 font-size: 1.1rem; cursor: no-drop;
                 line-height: 2rem;"
            >{{ this.year }}</div>
            <v-btn depressed small color="success" height="34"
                   @click.stop="year++"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
          <v-list nav class="transparent py-0 pr-0">
            <v-list-item
                         v-for="(item, idx) in edited_items"
                         class="mb-3 white elevation-2"
                         :key="`pre-${idx}`"
            >
              <v-list-item-icon class="mr-4">
                <v-icon size="24" :color="getItemIcon(item.day_type, 'color')">
                  {{ getItemIcon(item.day_type, 'icon') }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle class="text-wrap">
                  <strong>{{ item.date | formatDate }}</strong>. Назначено {{ getItemIcon(item.day_type, 'text') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                v-if="!edited_items.length"
                class="mb-3 white elevation-2"
            >
              <v-list-item-icon class="mr-4">
                <v-icon size="24" color="error">
                  mdi-alert-circle
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle class="text-wrap">
                  Жодних дій із датами не виконувалось
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import {endOfMonth, getDateFromString} from "@/utils/icons"
import calendarAPI from "@/utils/axios/accounting/calendar"
import {ALERT_SHOW} from "@/store/actions/alert";
import {mapGetters} from "vuex";
import {QUESTION_SHOW} from "@/store/actions/question";

const modalDeleteId = 'refill_calendar'

export default {
  name: "accounting_calendar",
  computed: {
    ...mapGetters({
      modalAnswer: 'question_answer'
    }),
  },
  data() {
    return {
      showMenu: false,
      delete_watcher: null,
      selected_day: null,
      x: 0,
      y: 0,
      year: null,
      is_new: false,
      items: [],
      edited_items: [],
      contextMenuItems: [
        { text: 'Зробити робочим', icon: 'mdi-briefcase-outline', color: 'success', operation: 'set-work' },
        { text: 'Зробити передсвятковим', icon: 'mdi-emoticon-wink-outline', color: 'primary', operation: 'set-preopening' },
        { text: 'Зробити святковим', icon: 'mdi-emoticon-excited-outline', color: 'teal', operation: 'set-holiday' },
        { text: 'Зробити вихідним', icon: 'mdi-emoticon-happy-outline', color: 'success', operation: 'set-opening' },
      ]
    }
  },
  methods: {
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.is_new = true
                  this.buildItems(true)
                  this.saveRows()
                }
              }
            }
          }
      )
    },
    deleteDialog() {
      const payload = {
        text: `Перезаповнити календар за ${this.year} рік. Перезаповнення призведе до вилучення усіх внесениз вручну змін.`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    updateItem(payload) {
      const date = getDateFromString(payload.date)
      const month = date.getMonth() + 1

      const weeks = this.items[month - 1].weeks
      weeks.forEach(w => {
        w.forEach(d => {
          if (d.date === payload.date) {
            let day_of_week = date.getDay()
            let day_of_week_ukr = 0
            let day_type = payload.day_type
            let class_type = ''

            if (day_of_week === 0) {
              day_of_week_ukr = 7
            } else {
              day_of_week_ukr = day_of_week
            }
            if (day_type === 'opening') {
              if (day_of_week_ukr === 6) {
                class_type = 'opening-1'
              }
              if (day_of_week_ukr === 7) {
                class_type = 'opening-2'
              }
            } else if (day_type === 'preopening') {
              class_type = 'preopening-1'
            }
            else if (day_type === 'holiday') {
              class_type = 'holiday-1'
            }
            else if (day_type === 'work') {
              class_type = ''
            }
            d.day_type = day_type
            d.class = class_type
          }
        })
      })
    },
    onContextMenuClick(payload) {
      if (!payload.operation) return false
      if (!this.selected_day) return false

      const operation = payload.operation.replace('set-', '')
      const new_payload = {
        date: this.selected_day.date,
        day_type: operation,
        year: this.year,
        id: 1
      }

      calendarAPI.update(new_payload)
        .then(response => response.data)
        .then(data => {
          this.edited_items = data
          this.updateItem(new_payload)
          this.$store.commit(ALERT_SHOW, { text: 'Дані збережено успішно', color: 'success' })
        })
        .catch(err => {
          const error = err.response.data.detail;
          this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
        })
          .finally(() => {
            this.showMenu = false
            this.selected_day = null
          })
    },
    clickOnElement(e, payload) {
      e.preventDefault()
      if (!payload.day) return

      this.showMenu = false
      this.selected_day = null
      this.x = e.clientX
      this.y = e.clientY
      this.$nextTick(() => {
        this.selected_day = payload
        this.showMenu = true
      })
    },
    getItemIcon(text, field='icon') {
      const icons = {
        'work': { icon: 'mdi-briefcase-outline', color: 'primary', text: 'робочим' },
        'preopening': { icon: 'mdi-emoticon-wink-outline', color: 'primary', text: 'передсвятковим' },
        'opening': { icon: 'mdi-emoticon-happy-outline', color: 'success', text: 'вихідним' },
        'holiday': { icon: 'mdi-emoticon-excited-outline', color: 'teal', text: 'святковим' },
      }
      return (icons[text] || { icon: 'mdi-account', color: 'secondary' })[field]
    },
    monthName() {
      return [
        'Січень',
        'Лютий',
        'Березень',
        'Квітень',
        'Травень',
        'Червень',
        'Липень',
        'Серпень',
        'Вересень',
        'Жовтень',
        'Листопад',
        'Грудень',
      ]
    },
    getWeeksArray() {
      const weeks = []
      weeks.push([{}, {}, {}, {}, {}, {}, {}])
      weeks.push([{}, {}, {}, {}, {}, {}, {}])
      weeks.push([{}, {}, {}, {}, {}, {}, {}])
      weeks.push([{}, {}, {}, {}, {}, {}, {}])
      weeks.push([{}, {}, {}, {}, {}, {}, {}])
      weeks.push([{}, {}, {}, {}, {}, {}, {}])

      return weeks
    },
    buildItems(refill=false) {
      const year = this.year
      const month_count = 12
      const local_items = []
      const month_names = this.monthName()

      for (let month = 1; month < month_count + 1; month++) {
        const day_in_month = endOfMonth(new Date(year, month - 1, 1), 'date').getDate()
        const month_object = {name: month_names[month - 1], month: month, year: year, weeks: this.getWeeksArray()}
        let week = 0
        let week_start = 0
        let week_day_end = 0

        for (let day = 1; day < day_in_month + 1; day++) {
          const day_of_week = new Date(year, month - 1, day).getDay()
          let day_of_week_ukr = 0

          if (day_of_week === 0) {
            day_of_week_ukr = 7
          } else {
            day_of_week_ukr = day_of_week
          }
          if (day === 1) {
            week = 1
            week_start = day_of_week_ukr
            week_day_end = (7 - week_start) + day
          }

          const date = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
          let find_in_edited = 0

          if (!refill) {
            find_in_edited = this.edited_items.find(e => e.date === date)
          }

          let day_type = ''
          let class_type = ''

          if (day_of_week_ukr <= 5) {
            day_type = 'work'
            class_type = ''
          } else if (day_of_week_ukr === 6) {
            day_type = 'opening'
            class_type = 'opening-1'
          } else if (day_of_week_ukr === 7) {
            day_type = 'opening'
            class_type = 'opening-2'
          }

          if (find_in_edited) {
            day_type = find_in_edited.day_type
            if (day_type === 'opening') {
              if (day_of_week_ukr === 6) {
                class_type = 'opening-1'
              }
              if (day_of_week_ukr === 7) {
                class_type = 'opening-2'
              }
            } else if (day_type === 'preopening') {
              class_type = 'preopening-1'
            }
            else if (day_type === 'holiday') {
              class_type = 'holiday-1'
            }
            else if (day_type === 'work') {
              class_type = ''
            }
          }

          month_object.weeks[week-1][day_of_week_ukr-1] = {
            day: day,
            month: month,
            year: year,
            date: date,
            type: day_type,
            day_of_week: day_of_week_ukr,
            class: class_type
          }


          if (week_day_end === day) {
            week += 1
            week_start = day_of_week_ukr < 7 ? day_of_week_ukr + 1 : 1
            week_day_end = (7 - week_start) + day + 1
          }
        }
        local_items.push(month_object)
      }

      this.items = local_items
    },
    fetchItems() {
      calendarAPI.get(this.year)
        .then(response => response.data)
        .then(data => {
          this.is_new = data.is_new
          this.edited_items = data.items
          this.buildItems()
          if (this.is_new) {
            this.$store.commit(ALERT_SHOW, { text: 'Виробничий календар не заповнений. Виконайте команду ЗАПОВНИТИ', color: 'secondary' })
          }
        })
        .catch(err => {
          const error = err.response.data.detail;
          this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
        })
    },
    saveRows() {
      const payload = []
      if (!this.is_new) this.$store.commit(ALERT_SHOW, {text: 'Збережено успішно', color: 'success'})
      this.items.forEach(month => {
        month.weeks.forEach(week => {
          week.forEach(day => {
            if (day.day) {
              payload.push(
                  {
                    date: day.date,
                    day_type: day.type,
                    default_type: day.type,
                    year: this.year
                  }
              )
            }
          })
        })
      })
      calendarAPI.create(payload)
        .then(response => response.data)
        .then(data => {
          if (data) {
            this.is_new = false
            this.$store.commit(ALERT_SHOW, {text: 'Збережено успішно', color: 'success'})
            this.edited_items = []
          }
        })
        .catch(err => {
          const error = err.response.data.detail;
          this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
        })
    }
  },
  created() {
    this.year = +(new Date().getFullYear())
    this.fetchItems()
    this.watch_modal_answer()
  },
  watch: {
    year: {
      immediate: false,
      handler() {
        this.fetchItems()
      }
    }
  },
  beforeDestroy() {
    if (this.delete_watcher) {
      this.delete_watcher()
    }
  }
}
</script>

<style scoped lang="scss">
  .calendar-header {
    .calendar-title {
      font-size: .86rem;
      padding-left: 8px;
      padding-top: 3px;
      padding-bottom: 3px;
      color: #2f8232;
      font-weight: 500;
    }
    .calendar-row {
      border-top: .5px solid #e7e7e7;

      .calendar-col {
        border-right: none;
        font-size: .9rem;

        &:nth-child(6) {
          color: var(--v-error-base);
        }
        &:nth-child(7) {
          color: var(--v-error-base);
        }
      }
    }
  }
  .calendar-body {
    .calendar-row {
      border-bottom: .5px solid #e7e7e7;

      &:nth-child(1) {
        border-top: .5px solid #e7e7e7;
      }

      .calendar-col {
        font-size: .82rem;
        &:hover {
          background-color: var(--v-secondary-base);
          color: white;
          font-weight: 500;
          cursor: pointer;
        }
      }
      .disable{
        background-color: #fffbef;
      }
      .opening-1 {
        color: #c46d37;
      }
      .opening-2 {
        color: #d32f2f;
      }
      .preopening-1 {
        background-color: var(--v-primary-base);
        color: white;
      }
      .holiday-1 {
        background-color: #009688;
        color: white;
      }
    }
  }

  .calendar-row {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;

    .calendar-col {
      flex: 0 0 14.285%;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: .5px solid #e7e7e7;

      &:nth-child(7) {
        border-right: none !important;
      }
    }
  }
</style>